<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Conductores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Orion</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">Conductores</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Conductores</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Documentos"
                      v-if="form.id"
                      >Documentos</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#evaluacion"
                      v-if="form.id"
                      >Evaluación</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Documentos"
                      data-toggle="tab"
                      href="#Estado"
                      v-if="form.id"
                      >Estado</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="row justify-content-center">
                            <div class="form-group col-md-2">
                              <label for="tipo_documento"
                                >Tipo identificacion</label
                              >
                              <v-select
                                class="form-control form-control-sm p-0"
                                placeholder="Tipo Documento"
                                label="descripcion"
                                v-model="tipoDocumentoObj"
                                :disabled="bloquear || acto == 2"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.tipoDocumentoObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                :options="listsForms.tipo_documentos"
                                @input="selectTipoDocumento()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="n_identificacion"
                                >Número de Documento</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="numero_documento"
                                v-model="form.numero_documento"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.numero_documento.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @input="datosConductor(form.numero_documento)"
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label for="nombres">Nombres</label>
                              <input
                                type="text"
                                v-model="form.nombres"
                                :class="
                                  $v.form.nombres.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="opcion == 1 || bloquear || acto == 2"
                                class="form-control form-control-sm"
                                id="nombres"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="apellidos">Apellidos</label>
                              <input
                                type="text"
                                v-model="form.apellidos"
                                :disabled="bloquear || acto == 2"
                                class="form-control form-control-sm"
                                id="apellidos"
                                :class="
                                  $v.form.apellidos.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label for="email">Email</label>
                              <input
                                v-model="form.email"
                                :disabled="bloquear || acto == 2"
                                type="email"
                                class="form-control form-control-sm"
                                id="email"
                                :class="
                                  $v.form.email.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-5">
                              <label for="tipo_documento">Empresa</label>
                              <v-select
                                class="form-control form-control-sm p-0"
                                v-model="empresaObj"
                                :disabled="bloquear || acto == 2"
                                placeholder="Empresas"
                                label="descripcion"
                                :options="listsForms.empresas"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.empresaObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                @input="selectEmpresa()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="celular_principal">Activo</label>
                              <v-select
                                class="form-control form-control-sm p-0"
                                v-model="activoObj"
                                :disabled="bloquear || acto == 2"
                                placeholder="Activos"
                                label="descripcion"
                                :options="listsForms.activos"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.activoObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                @input="selectActivo()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="celular_alterno">Tipo Contrato</label>
                              <v-select
                                class="form-control form-control-sm p-0"
                                v-model="tipoContratoObj"
                                :disabled="bloquear || acto == 2"
                                placeholder="Tipos Contratos"
                                label="descripcion"
                                :options="listsForms.tipos_contratos"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.tipoContratoObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                @input="selectTipoContrato()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-4">
                              <label for="clase_vehiculo"
                                >Clase De Vehículo</label
                              >
                              <v-select
                                class="form-control p-0"
                                v-model="claseVhObj"
                                :disabled="bloquear || acto == 2"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.claseVhObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                placeholder="Clase Vehículo"
                                label="descripcion"
                                :options="listsForms.clases_vehiculos"
                                @input="selectClaseVehiculo()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-2">
                              <label for="fecha_nacimiento">Fecha Runt</label>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                id="fecha_nacimiento"
                                v-model="form.fecha_insc_runt"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.fecha_insc_runt.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label for="fecha_nacimiento"
                                >Fecha de nacimiento</label
                              >
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="form.fecha_nacimiento"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.fecha_nacimiento.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label for="tipo_sangre"
                                >Restricción (Licencia)</label
                              >
                              <select
                                class="form-control form-control-sm"
                                v-model="form.restriccion_licencia"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.restriccion_licencia.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            <div class="form-group col-md-2">
                              <label for="num_lincencia_conduccion"
                                >Comparendos</label
                              >
                              <select
                                class="form-control form-control-sm"
                                v-model="form.comparendos"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.comparendos.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="categoria_licencia">Categoria</label>
                              <v-select
                                class="form-control form-control-sm p-0"
                                v-model="categoriaLicenciaObj"
                                :disabled="bloquear || acto == 2"
                                :class="[
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                  $v.categoriaLicenciaObj.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                ]"
                                placeholder="Categoria Licencia"
                                label="descripcion"
                                :options="listsForms.categoria_licencias"
                                @input="selectCategoriaLicencia()"
                              >
                              </v-select>
                            </div>
                            <div class="form-group col-md-2">
                              <label for="edad">Años Experiencia</label>
                              <input
                                type="text"
                                v-model="form.experiencia_cond"
                                :disabled="bloquear || acto == 2"
                                :class="
                                  $v.form.experiencia_cond.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                class="form-control form-control-sm"
                                id="edad"
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label>Observaciones</label>
                              <textarea
                                v-model="form.observaciones"
                                :disabled="bloquear || acto == 2"
                                cols="30"
                                rows="3"
                                class="form-control form-control-sm"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label for="imagen" class="text-center"
                            >Fotografía</label
                          >
                          <figure class="form-group" v-if="miniLogo">
                            <div class="button-container">
                              <img
                                :src="miniLogo"
                                alt="Logo"
                                width="236"
                                height="125"
                                class="rounded mx-auto d-block"
                                :disabled="bloquear || acto == 2"
                              />
                              <a
                                @click="destroyImage()"
                                id="BotonEliminar"
                                :disabled="bloquear || acto == 2"
                                v-if="miniLogo"
                                href="#"
                                >Eliminar <i class="fas fa-window-close"></i
                              ></a>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Documentos" v-if="form.id">
                    <SolicitudConductorDocumento
                      v-if="form.id != null"
                      ref="SolicitudConductorDocumento"
                    />
                  </div>
                  <div class="tab-pane fade" id="evaluacion" v-if="form.id">
                    <div class="card-body" v-if="intentos[0]">
                      <div
                        v-for="(intento, index) in intentos"
                        :key="intento.id"
                      >
                        <div
                          :class="
                            intento.evaluacionCond.resultado >=
                            intento.porcentaje
                              ? 'card card-success card-outline'
                              : 'card card-danger card-outline'
                          "
                        >
                          <div class="card-header pt-2 pb-2 d-flex">
                            <h3
                              :class="
                                intento.evaluacionCond.resultado >=
                                intento.porcentaje
                                  ? 'card-title mr-auto p-2 text-success'
                                  : 'card-title mr-auto p-2 text-danger'
                              "
                            >
                              <i class="far fa-folder"></i>
                              Intento {{ index + 1 }}
                            </h3>
                            <h3
                              :class="
                                intento.evaluacionCond.resultado >=
                                intento.porcentaje
                                  ? 'card-title p-2 text-success'
                                  : 'card-title p-2 text-danger'
                              "
                            >
                              {{
                                intento.evaluacionCond.resultado >=
                                intento.porcentaje
                                  ? "Aprobado"
                                  : "No Aprobado"
                              }}
                            </h3>
                            <div class="card-tools p-2">
                              <button
                                type="button"
                                class="btn btn-tool"
                                data-card-widget="collapse"
                                data-toggle="tooltip"
                                title="Collapse"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                          <div class="card-body" style="display: block">
                            <div class="row">
                              <div class="col-12 col-sm-6 com-md-3">
                                <div class="info-box">
                                  <span
                                    class="info-box-icon bg-warning elevation-1"
                                    ><i class="fas fa-clock"></i
                                  ></span>
                                  <div class="info-box-content">
                                    <span class="info-box-text"
                                      >Fecha Inicio</span
                                    >
                                    <span class="info-box-number">
                                      {{
                                        intento.evaluacionCond
                                          ? intento.evaluacionCond.fecha_ini
                                          : null
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-6 com-md-3">
                                <div class="info-box">
                                  <span
                                    class="info-box-icon bg-warning elevation-1"
                                    ><i class="fas fa-clock"></i
                                  ></span>
                                  <div class="info-box-content">
                                    <span class="info-box-text">Fecha Fin</span>
                                    <span class="info-box-number">
                                      {{
                                        intento.evaluacionCond
                                          ? intento.evaluacionCond.fecha_fin
                                          : null
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-4 com-md-3">
                                <div class="info-box">
                                  <span
                                    class="info-box-icon bg-info elevation-1"
                                  >
                                    <i class="fas fa-file"></i>
                                  </span>
                                  <div class="info-box-content">
                                    <span class="info-box-text"
                                      >Evaluación</span
                                    >
                                    <span class="info-box-number">
                                      {{ intento.evaluacion }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-4 com-md-3">
                                <div class="info-box">
                                  <span
                                    :class="
                                      intento.evaluacionCond.resultado >=
                                      intento.porcentaje
                                        ? 'info-box-icon bg-success elevation-1'
                                        : 'info-box-icon bg-danger elevation-1'
                                    "
                                  >
                                    <i class="fas fa-percent"></i>
                                  </span>
                                  <div class="info-box-content">
                                    <span class="info-box-text">Puntaje</span>
                                    <span class="info-box-number">
                                      {{ intento.evaluacionCond.resultado }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-4 com-md-3">
                                <div class="info-box">
                                  <span
                                    class="info-box-icon bg-purple elevation-1"
                                  >
                                    <i class="fas fa-clock"></i>
                                  </span>
                                  <div class="info-box-content">
                                    <span class="info-box-text"
                                      >Tiempo Total</span
                                    >
                                    <span class="info-box-number">
                                      {{
                                        tiempos[index]
                                          ? tiempos[index].totalMin
                                          : ""
                                      }}
                                      Min
                                      {{
                                        tiempos[index]
                                          ? tiempos[index].totalSeg
                                          : ""
                                      }}
                                      Sec
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 text-right">
                                <button
                                  class="btn bg-primary col-md-1"
                                  data-toggle="modal"
                                  data-target="#Modal_detalles_evaluacion"
                                  @click="abrirModal(intento.detEvaluacion)"
                                >
                                  Detalles
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0" v-else>
                      <div class="row justify-content-center">
                        <div class="alert alert-warning mt-3">
                          <h5>
                            <i class="icon fas fa-exclamation-triangle"></i>
                            Atención!
                          </h5>
                          No se encontró infomación de la evaluación!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Estado" v-if="form.id">
                    <div
                      class="card-body pb-0"
                      v-if="
                        /* estadoEditable(estado_anterior) && */
                        $store.getters.can('hse.solicitudesConductores.edit')
                      "
                    >
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Fecha Vencimiento</label>
                            <input
                              type="date"
                              v-model="fecha_vencimiento"
                              :disabled="bloquear || acto == 2"
                              class="form-control form-control-sm"
                              @change="validacionFechaVencimiento()"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.estado"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="acto == 2"
                            >
                              <option
                                v-for="estado in listsForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                            <span
                              v-if="form.Estado"
                              class="badge"
                              :class="[
                                estado_anterior == 1
                                  ? 'badge-secondary'
                                  : estado_anterior == 2
                                  ? 'badge-success'
                                  : estado_anterior == 3
                                  ? 'badge-danger'
                                  : estado_anterior == 4
                                  ? 'bg-olive'
                                  : estado_anterior == 5
                                  ? 'badge-warning'
                                  : estado_anterior == 6
                                  ? 'badge-info'
                                  : estado_anterior == 7
                                  ? 'bg-navy'
                                  : estado_anterior == 8
                                  ? 'bg-lime'
                                  : '',
                              ]"
                              >Estado Actual: {{ form.Estado }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Observaciones</label>
                            <textarea
                              v-model="observaciones_estado"
                              class="form-control form-control-sm"
                              style="resize: vertical"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <table
                        v-if="historico.length > 0"
                        class="table table-bordered table-hover table-sm m-0"
                        style="font-size: 0.9em"
                      >
                        <thead class="bg-light">
                          <tr class="text-center">
                            <th class="col-2">Fecha</th>
                            <th class="col-2">Estado Anterior</th>
                            <th class="col-2">Estado Nuevo</th>
                            <th class="col-3">Observación</th>
                            <th>Realizado por</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="hist in historico" :key="hist.id">
                            <td>{{ hist.fecha_hora }}</td>
                            <td class="text-center">
                              <span
                                v-if="hist.estado_anterior"
                                class="badge"
                                :class="[
                                  hist.estado_anterior == 1
                                    ? 'badge-secondary'
                                    : hist.estado_anterior == 2
                                    ? 'badge-success'
                                    : hist.estado_anterior == 3
                                    ? 'badge-danger'
                                    : hist.estado_anterior == 4
                                    ? 'bg-olive'
                                    : hist.estado_anterior == 5
                                    ? 'badge-warning'
                                    : hist.estado_anterior == 6
                                    ? 'badge-info'
                                    : hist.estado_anterior == 7
                                    ? 'bg-navy'
                                    : hist.estado_anterior == 8
                                    ? 'bg-lime'
                                    : '',
                                ]"
                                >{{ hist.nEstadoAnt }}</span
                              >
                            </td>
                            <td class="text-center">
                              <span
                                class="badge"
                                :class="[
                                  hist.estado_nuevo == 1
                                    ? 'badge-secondary'
                                    : hist.estado_nuevo == 2
                                    ? 'badge-success'
                                    : hist.estado_nuevo == 3
                                    ? 'badge-danger'
                                    : hist.estado_nuevo == 4
                                    ? 'bg-olive'
                                    : hist.estado_nuevo == 5
                                    ? 'badge-warning'
                                    : hist.estado_nuevo == 6
                                    ? 'badge-info'
                                    : hist.estado_nuevo == 7
                                    ? 'bg-navy'
                                    : hist.estado_nuevo == 8
                                    ? 'bg-lime'
                                    : '',
                                ]"
                                >{{ hist.nEstadoNuevo }}</span
                              >
                            </td>
                            <td>
                              <small>{{ hist.observacion }}</small>
                            </td>
                            <td>{{ hist.user ? hist.user.name : "" }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="row justify-content-center">
                        <div class="alert alert-warning mt-3">
                          <h5>
                            <i class="icon fas fa-exclamation-triangle"></i>
                            Atención!
                          </h5>
                          No existe ningún cambio de estado registrado.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="
                      !$v.form.$invalid &&
                        !$v.claseVhObj.$invalid &&
                        !$v.empresaObj.$invalid &&
                        !$v.activoObj.$invalid &&
                        !$v.tipoContratoObj.$invalid &&
                        !$v.tipoDocumentoObj.$invalid &&
                        !$v.categoriaLicenciaObj.$invalid
                    "
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button @click="back()" class="btn bg-secondary col-md-1">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
            <!-- MODAL DETALLES EVALUACIÖN -->
            <div class="modal fade" id="Modal_detalles_evaluacion">
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                    <h4 class="modal-title text-white">Detalles Evaluación</h4>
                    <button
                      type="button"
                      class="close text-white"
                      data-dismiss="modal"
                      aria-label="Close"
                      ref="closeModal"
                      id="close-modal"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          id="docs"
                          class="table table-bordered table-hover table-sm text-center"
                        >
                          <thead>
                            <tr>
                              <th>Pregunta</th>
                              <th>Respuesta</th>
                              <th>Correcta</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="detalle in detEvaluacion"
                              :key="detalle.id"
                              :class="
                                detalle.puntaje
                                  ? 'alert-default-success'
                                  : 'alert-default-danger'
                              "
                            >
                              <td>
                                {{ detalle.pregunta_evalaciones.descripcion }}
                              </td>
                              <td>
                                {{
                                  detalle.respuesta_preg_evalaciones.descripcion
                                }}
                              </td>
                              <td>
                                <i
                                  :class="
                                    detalle.puntaje == 1
                                      ? 'fas fa-check-circle'
                                      : 'fas fa-times-circle'
                                  "
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.modal-content -->
              </div>
              <!-- /.modal-dialog -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  numeric,
  helpers,
  maxLength,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import SolicitudConductorDocumento from "./SolicitudConductorDocumento";
import Loading from "../../../../components/Loading";
const alpha = helpers.regex("alpha", /^[a-z A-Z \sÁÉÍÓÚáéíóúÑñ]*$/);

export default {
  name: "SolicitudConductorForm",
  components: {
    Loading,
    vSelect,
    SolicitudConductorDocumento,
  },

  data() {
    return {
      evaluacion_aprobada: false,
      cargando: false,
      intentos: [],
      tiempos: [],
      historico: [],
      detEvaluacion: [],
      observaciones_estado: "",
      fecha_ven_anterior: null,
      claseVhObj: {},
      empresaObj: {},
      activoObj: {},
      tipoContratoObj: {},
      tipoDocumentoObj: {},
      categoriaLicenciaObj: {},
      form: {
        id: null,
        conductor_id: null,
        empresa: null,
        empresa_id: null,
        activo_id: null,
        nombres: null,
        apellidos: null,
        numero_documento: null,
        tipo_contrato: null,
        tipo_contrato_id: null,
        experiencia_cond: null,
        clase_vehiculo: null,
        clase_vehiculo_id: null,
        comparendos: null,
        restriccion_licencia: null,
        fecha_insc_runt: null,
        tipo_documento: null,
        tipo_documento_id: null,
        fecha_nacimiento: null,
        email: null,
        categoria_licencia: null,
        categoria_licencia_id: null,
        observaciones: "",
        observaciones_estado: "",
        estado: null,
        Estado: null,
      },
      bloquear: null,
      tipo_documento: null,
      fecha_menor: null,
      estado_anterior: null,
      tipo_contrato: null,
      activo: null,
      fecha_vencimiento: null,
      categoria_licencia: null,
      empresa: null,
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      acto: "",
      opcion: null,
      metodo: "",
      transportadora: {},
      lineaNegocio: {},
      listsForms: {
        tipo_documentos: [],
        activos: [],
        tipos_contratos: [],
        categoria_licencias: [],
        empresas: [],
        conductores: [],
        clases_vehiculos: [],
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      numero_documento: {
        required,
        numeric,
      },
      email: {
        required,
        email,
      },
      nombres: {
        required,
        alpha,
      },
      apellidos: {
        required,
        alpha,
      },
      fecha_nacimiento: {
        required,
      },
      empresa: {
        required,
      },
      experiencia_cond: {
        required,
        numeric,
        maxLength: maxLength(2),
      },
      fecha_insc_runt: {
        required,
      },
      restriccion_licencia: {
        required,
      },
      comparendos: {
        required,
      },
      estado: {
        required,
      },
    },
    claseVhObj: {
      required,
    },
    empresaObj: {
      required,
    },
    activoObj: {
      required,
    },
    tipoContratoObj: {
      required,
    },
    tipoDocumentoObj: {
      required,
    },
    categoriaLicenciaObj: {
      required,
    },
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      await this.getTipoDocumentos();
      await this.getActivos();
      await this.getTiposContratos();
      await this.getCategoriaLicencias();
      await this.getEmpresas();
      await this.getTiposContratos();
      await this.getConductores();
      await this.getClasesVehiculos();

      let me = this;
      await axios
        .get(
          "/api/hse/HseSolicitudesConductores/" + (await this.$route.params.id)
        )
        .then(async (response) => {
          me.cargando = false;
          me.form = await response.data;
          me.metodo = "PUT";
          await me.getEvaluacionesConductor(me.form.id);

          me.historico = response.data["historicos_estados"];

          // Se toma el estado de la solicitud para validar la gestion a realizar en el módulo
          me.estado_anterior = me.form.estado;
          me.getListaEstados(me.estado_anterior);

          let fechas = [];
          var documentos_files = response.data["documentos_files"];
          if (documentos_files) {
            documentos_files.forEach((element) => {
              fechas.push(element.fecha_vencimiento);
            });
            me.fecha_menor = moment("0000-00-00").format("YYYY-MM-DD");
            fechas.forEach(function(fecha, i) {
              if (fecha < me.fecha_menor && fecha != "0000-00-00") {
                me.fecha_menor = fecha;
              }
            });
            me.fecha_vencimiento = me.fecha_menor;
          }

          if (me.form.link_foto) {
            me.miniLogo = me.uri_docs + me.form.link_foto;
          }

          if (me.form.tipo_documento) {
            me.form.tipo_documento_id = me.form.tipo_documento;
            let tipoDoc = me.listsForms.tipo_documentos.find(
              (t) => t.numeracion === me.form.tipo_documento
            );
            me.tipoDocumentoObj = tipoDoc;
          }

          if (me.form.activo) {
            me.form.activo_id = me.form.activo;
            let activo = me.listsForms.activos.find(
              (a) => a.numeracion === me.form.activo
            );
            me.activoObj = activo;
          }

          if (me.form.empresa) {
            me.form.empresa_id = me.form.empresa;
            let empresa = me.listsForms.empresas.find(
              (e) => e.numeracion === me.form.empresa
            );
            me.empresaObj = empresa;
          }

          if (me.form.categoria_licencia) {
            me.form.categoria_licencia_id = me.form.categoria_licencia;
            let categoria = me.listsForms.categoria_licencias.find(
              (c) => c.numeracion === me.form.categoria_licencia
            );
            me.categoriaLicenciaObj = categoria;
          }

          if (me.form.tipo_contrato) {
            me.form.tipo_contrato_id = me.form.tipo_contrato;
            let tipoCon = me.listsForms.tipos_contratos.find(
              (c) => c.numeracion === me.form.tipo_contrato
            );
            me.tipoContratoObj = tipoCon;
          }

          if (me.form.clase_vehiculo) {
            me.form.clase_vehiculo_id = me.form.clase_vehiculo;
            let claseVh = me.listsForms.clases_vehiculos.find(
              (c) => c.numeracion === me.form.clase_vehiculo
            );
            me.claseVhObj = claseVh;
          }

          /*  if (me.form.estado) {
            if (me.form.estado && me.form.estado == 8) {
              me.bloquear = true;
            }
          } */

          if (
            me.form.fecha_fin_pase_campo != null &&
            me.form.fecha_fin_pase_campo != "0000-00-00"
          ) {
            me.fecha_vencimiento = me.form.fecha_fin_pase_campo;
          }
          me.fecha_ven_anterior = me.fecha_vencimiento;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEvaluacionesConductor(id) {
      await axios
        .get("/api/hse/HseSolicitudesConductores/EvaluacionConductor/" + id)
        .then((response) => {
          this.intentos = response.data;
          if (Array.isArray(this.intentos)) {
            this.intentos.forEach((intento) => {
              let fecha_fin = moment(intento.evaluacionCond.fecha_fin);
              let fecha_ini = moment(intento.evaluacionCond.fecha_ini);
              let diff = moment.duration(fecha_fin.diff(fecha_ini));
              let totalMin = diff.minutes();
              let totalSeg = diff.seconds();
              let tiempo = {
                idEvaluacionCond: intento.evaluacionCond.id,
                totalMin: totalMin,
                totalSeg: totalSeg,
              };
              this.tiempos.push(tiempo);
              if (intento.evaluacionCond.resultado >= intento.porcentaje) {
                this.evaluacion_aprobada = true;
              }
            });
          }
        });
    },

    validacionFechaVencimiento() {
      if (this.fecha_vencimiento > this.fecha_menor) {
        this.$swal({
          icon: "error",
          title: `NO ES POSIBLE ASIGNAR UNA FECHA MAYOR A  ` + this.fecha_menor,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.fecha_vencimiento = this.fecha_menor;
      }
    },

    getConductores() {
      axios
        .get("api/hse/HseSolicitudesConductores/listaCoductores")
        .then((response) => {
          this.listsForms.conductores = response.data;
        });
    },

    getClasesVehiculos() {
      axios.get("/api/lista/163").then((response) => {
        this.listsForms.clases_vehiculos = response.data;
      });
    },

    getTipoDocumentos() {
      axios.get("/api/lista/6").then((response) => {
        this.listsForms.tipo_documentos = response.data;
      });
    },

    getActivos() {
      axios.get("/api/lista/117").then((response) => {
        this.listsForms.activos = response.data;
      });
    },

    getTiposContratos() {
      axios.get("/api/lista/26").then((response) => {
        this.listsForms.tipos_contratos = response.data;
      });
    },

    getCategoriaLicencias() {
      axios.get("/api/lista/17").then((response) => {
        this.listsForms.categoria_licencias = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/lista/164").then((response) => {
        this.listsForms.empresas = response.data;
      });
    },

    getEstados(lista = []) {
      axios
        .get("/api/lista/175", {
          params: { in_number: lista },
        })
        .then((response) => {
          this.listsForms.estados = response.data;
        });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresaObj) {
        this.form.empresa_id = this.empresaObj.numeracion;
      }
    },

    selectActivo() {
      this.form.activo_id = null;
      if (this.activoObj) {
        this.form.activo_id = this.activoObj.numeracion;
      }
    },

    selectTipoContrato() {
      this.form.tipo_contrato_id = null;
      if (this.tipoContratoObj) {
        this.form.tipo_contrato_id = this.tipoContratoObj.numeracion;
      }
    },

    selectCategoriaLicencia() {
      this.form.categoria_licencia_id = null;
      if (this.categoriaLicenciaObj) {
        this.form.categoria_licencia_id = this.categoriaLicenciaObj.numeracion;
      }
    },

    selectClaseVehiculo() {
      this.form.clase_vehiculo_id = null;
      if (this.claseVhObj) {
        this.form.clase_vehiculo_id = this.claseVhObj.numeracion;
      }
    },

    selectTipoDocumento() {
      this.form.tipo_documento_id = null;
      if (this.tipoDocumentoObj) {
        this.form.tipo_documento_id = this.tipoDocumentoObj.numeracion;
      }
    },

    datosConductor(documento) {
      const conductor = this.listsForms.conductores.find(
        (conductores) => conductores.numero_documento === documento
      );
      if (conductor) {
        this.form.nombres = conductor.nombres;
        this.form.apellidos = conductor.apellidos;
        this.form.email = conductor.email;
        this.form.fecha_nacimiento = conductor.fecha_nacimiento;
        this.form.tipo_documento = conductor.tipo_documento;
        this.form.conductor_id = conductor.id;

        if (conductor.tipo_documento) {
          const tipoDocumento = this.listsForms.tipo_documento.find(
            (tipoDoc) => tipoDoc.numeracion === conductor.tipo_documento
          );
          this.tipoDocumentoObj = tipoDocumento;
          this.selectTipoDocumento();
        }

        if (conductor.categoria_licencia) {
          const categoriaLicencia = this.listsForms.categorias_licencias.find(
            (catLicencia) =>
              catLicencia.numeracion === conductor.categoria_licencia
          );
          this.categoriaLicenciaObj = categoriaLicencia;
          this.selectCategoriaLicencia();
        }
      } else {
        this.form.nombres = null;
        this.form.apellidos = null;
        this.form.email = null;
        this.form.fecha_nacimiento = null;
        this.form.tipo_documento = null;
        this.form.conductor_id = null;
        this.form.categoria_licencia = null;
        this.selectCategoriaLicencia();
      }
    },

    save() {
      let me = this;
      /*let fecha_actual = moment().format("YYYY-MM-DD");
       if (fecha_actual > me.fecha_vencimiento && me.form.estado != 3) {
        me.$swal({
          icon: "warning",
          title: `La fecha de vencimiento es menor a la fecha actual.`,
          text:
            "El único estado que se podra guardar con fecha vencida es 'Rechazado'.",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
      } else { */
      if (me.form.estado == 2 && me.evaluacion_aprobada === false) {
        me.$swal({
          icon: "warning",
          title: `Aprobar Evaluación`,
          text:
            "Es necesario aprobar la evalución para cambiar a estado 'Aceptado'.",
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
      } else {
        me.cargando = true;
        let observacion_estado =
          me.estado_anterior != me.form.estado ? me.observaciones_estado : null;
        if (observacion_estado == null || observacion_estado == "") {
          observacion_estado = me.observaciones_estado
            ? me.observaciones_estado
            : me.fecha_ven_anterior != me.fecha_vencimiento &&
              me.estado_anterior == me.form.estado
            ? "Actualización de Fecha Vencimiento: " +
              me.fecha_ven_anterior +
              " por la fecha: " +
              me.fecha_vencimiento
            : "Actualización automática del sistema por cambios en la solicitud";
        }
        let estado = null;
        if (me.estado_anterior === 5 || me.estado_anterior === 3) {
          estado = 6;
        } else {
          estado = me.form.estado;
        }
        let datos = {
          id: me.form.id ? me.form.id : null,
          conductor_id: me.form.conductor_id ? me.form.conductor_id : "",
          empresa: me.form.empresa_id,
          activo: me.form.activo_id,
          nombres: me.form.nombres,
          apellidos: me.form.apellidos,
          numero_documento: me.form.numero_documento,
          tipo_contrato: me.form.tipo_contrato_id,
          experiencia_cond: me.form.experiencia_cond,
          clase_vehiculo: me.form.clase_vehiculo_id,
          comparendos: me.form.comparendos,
          restriccion_licencia: me.form.restriccion_licencia,
          fecha_insc_runt: me.form.fecha_insc_runt,
          tipo_documento: me.form.tipo_documento_id,
          fecha_nacimiento: me.form.fecha_nacimiento,
          email: me.form.email,
          categoria_licencia: me.form.categoria_licencia_id,
          observaciones: me.form.observaciones,
          observaciones_estado: observacion_estado,
          fecha_fin_pase_campo: me.fecha_vencimiento,
          fecha_ven_anterior: me.fecha_ven_anterior,
          estado: estado,
          estado_anterior: me.estado_anterior,
        };
        axios({
          method: me.metodo,
          url: "/api/hse/HseSolicitudesConductores",
          data: datos,
        })
          .then((response) => {
            me.$swal({
              icon: "success",
              title: "Se guardo la solicitud correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.observaciones_estado = "";
            me.getIndex();
          })
          .catch((e) => {
            if (e.response.status == 500) {
              me.error = e.response.data.message;
              if (me.error.includes("1062 Duplicate entry")) {
                me.$swal({
                  icon: "error",
                  title:
                    "El numero de documento ya se encuentra creada en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                me.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              me.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
        me.cargando = false;
      }
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    destroyImage() {
      this.miniLogo = null;
      this.fileFotografia = null;
    },

    back() {
      return this.$router.replace("/Hse/SolicitudesConductores");
    },

    abrirModal(detalles) {
      this.detEvaluacion = detalles;
    },

    estadoEditable(estado) {
      return estado == 6 || estado == 8 || estado == 2 ? true : false;
    },

    getListaEstados(estado) {
      let lista = [];
      switch (estado) {
        case 2:
          lista = [3, 2];
          break;

        case 6:
          lista = [2, 3, 6];
          break;

        case 8:
          lista = [2, 3, 8];
          break;

        case 4:
          lista = [4];
          break;

        default:
          lista = [100];
          break;
      }
      this.getEstados(lista);
    },
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
